import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSoundRecords(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/membership/member/sound-records", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSoundRecord(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/membership/member/sound-record/" + id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    submitSoundRecord(ctx, record) {
      return new Promise((resolve, reject) => {
        axios
          .post("/membership/content/upload", record)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
